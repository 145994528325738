import React from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import AreaMap from "../components/areaMap";

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
	const featuredImage = {
		fluid:
			post.blogFields.featuredImage?.localFile?.childImageSharp
				?.gatsbyImageData,
		alt:
			post.blogFields.featuredImage?.altText ||
			`Image of Jeff Seymour at Seymour Locksmiths`,
	};

	return (
		<Layout>
			<Container className="my-20">
				<GatsbySeo
					title={`${post?.blogSeo?.metaTitle || post.title}`}
					description={post.blogSeo.metaDescription}
					language="en"
					openGraph={{
						title: `${post?.blogSeo?.metaTitle || post.title}`,
						description: `${post.blogSeo.metaDescription}`,
						url: ``,
						type: "article",
						images: [
							{
								url: `${post.blogFields.featuredImage.uri}`,
							},
						],
					}}
					twitter={{
						handle: "@jefflocksmith",
						site: "@jefflocksmith",
						cardType: "summary",
					}}
				/>
				<Row className="justify-content-center">
					<Col xs={12} xl={9}>
						<header>
							<h1>{parse(post.title)}</h1>

							<p>{post.date}</p>
							<p className="italic">Written by Jeff Seymour</p>

							{featuredImage?.fluid && (
								<GatsbyImage
									image={featuredImage?.fluid}
									alt={featuredImage.alt}
									style={{ marginBottom: 50 }}
								/>
							)}
						</header>

						{!!post.content && <section>{parse(post.content)}</section>}

						<nav className="mt-5">
							<ul
								style={{
									display: `flex`,
									flexWrap: `wrap`,
									justifyContent: `space-between`,
									listStyle: `none`,
									padding: 0,
								}}
							>
								<li>
									{previous && (
										<Link to="/resources" rel="prev">
											← Back to Resources
										</Link>
									)}
								</li>
							</ul>
						</nav>
					</Col>
				</Row>
			</Container>
			<AreaMap />
		</Layout>
	);
};

export default BlogPostTemplate;

export const pageQuery = graphql`
	query BlogPostById(
		$id: String!
		$previousPostId: String
		$nextPostId: String
	) {
		post: wpPost(id: { eq: $id }) {
			id
			excerpt
			content
			title
			date(formatString: "MMMM DD, YYYY")
			blogSeo {
				metaDescription
				metaTitle
			}
			blogFields {
				featuredImage {
					altText
					uri
					localFile {
						childImageSharp {
							gatsbyImageData(
								quality: 70
								placeholder: BLURRED
								layout: FULL_WIDTH
							)
						}
					}
				}
			}
		}
		previous: wpPost(id: { eq: $previousPostId }) {
			uri
			title
		}
		next: wpPost(id: { eq: $nextPostId }) {
			uri
			title
		}
	}
`;
